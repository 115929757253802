<template>
	
	<div class="input-group color-picker" ref="colorpicker">
		
		<!--button class="buttonPicker" @click="element.displayPicker = !element.displayPicker" :style="{backgroundColor: element.color.hex != '' ? element.color.hex : '#eee' }">&nbsp;</button>
		<sketch-picker v-model="element.color.hex" @input="updateValue(element.color)" v-if="element.displayPicker" style="position: absolute; z-index: 9;" /-->
		<!--chrome-picker v-model="element.color.hex" @input="updateValue(element.color)" v-if="element.displayPicker" style="position: absolute; z-index: 9;" /-->
		
		<input type="hidden" class="form-control" v-model="colorValue" @focus="showPicker()" @input="updateFromInput" />
		<span class="input-group-addon color-picker-container">
			<span class="current-color" :style="'background-color: ' + color" @click="togglePicker()"></span> <!-- colorValue -->
			<sketch-picker :value="colors" @input="updateFromPicker" v-if="displayPicker" />
		</span>
	</div>
	
</template>

<script>
	
	import { Sketch } from 'vue-color'
	// import { Chrome } from 'vue-color'
	
	export default {
		name:'colorpicker',
		components: {
			
			'sketch-picker': Sketch
			// 'chrome-picker': Chrome,
		},
		data() {
			return {
				colors: {
					hex: '#000000',
				},
				colorValue: '',
				displayPicker: false,
			};
		},
		props: ['color'],
		methods: {
			setColor(color) {
				this.updateColors(color);
				if( this.colorValue != '' ) {
					this.$emit('onChange');
				}
				this.colorValue = color;
			},
			updateColors(color) {
				if(color.slice(0, 1) == '#') {
					this.colors = {
						hex: color
					};
				}
				else if(color.slice(0, 4) == 'rgba') {
					var rgba = color.replace(/^rgba?\(|\s+|\)$/g,'').split(','),
						hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
					this.colors = {
						hex: hex,
						a: rgba[3],
					}
				}
			},
			showPicker() {
				document.addEventListener('click', this.documentClick);
				this.displayPicker = true;
			},
			hidePicker() {
				document.removeEventListener('click', this.documentClick);
				this.displayPicker = false;
			},
			togglePicker() {
				this.displayPicker ? this.hidePicker() : this.showPicker();
			},
			updateFromInput() {
				this.updateColors(this.colorValue);
			},
			updateFromPicker(color) {
				this.colors = color;
				if(color.rgba.a == 1) {
					this.colorValue = color.hex;
				}
				else {
					this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
				}
			},
			documentClick(e) {
				var el = this.$refs.colorpicker,
					target = e.target;
				if(el !== target && !el.contains(target)) {
					this.hidePicker()
				}
			}
		},
		watch: {
			colorValue(val) {
				if(val) {
					this.updateColors(val);
					this.$emit('input', val);
					//document.body.style.background = val;
				}
			},
			color(val) {
				if(val) {
					this.setColor(val || '#000000');
				}
			},
		},
		
		
		mounted() {
			this.setColor(this.color || '#000000');
		},
		computed: {
			
		}
	}
</script>
<style lang="scss">
	
	h1 {
		height: 120px;
		line-height: 120px;
		text-align: center;
	}
	.vc-chrome, .vc-sketch {
		position: absolute !important;
		top: 35px;
		right: 0;
		z-index: 9;
	}
	.current-color {
		display: inline-block;
		width: 16px;
		height: 16px;
		background-color: #000;
		cursor: pointer;
		vertical-align: sub;
		border-radius: 100%;
	}
	.footer {
		margin-top: 20px;
		text-align: center;
	}
	
</style>
