<template>
	<center>
		<svg class="stat-circle" width="56" viewBox="0 0 20 20">
			<circle class="bg" cx="10" cy="10" r="8"></circle>
			<circle :class="['progress', PercentColor]" cx="10" cy="10" r="8" :data-percentage="percentage"></circle>
			<text x="50%" y="55%">{{ ParsePercent }}%</text>
		</svg>
	</center>
</template>

<script>
	export default {
		name:'progressMd',
		components: {
			
		},
		data() {
			return {
				
			};
		},
		props: {
			percentage: {}
		},
		methods: {
			
		},
		mounted() {
			
		},
		
		watch: {
			percentage(val) {
				var statCircle = document.querySelectorAll(".stat-circle circle");
				for (var i = 0; i < statCircle.length; i++) {
					var p = parseFloat(val);
					var off = -51 -((51 / 100) * p);
					new window.TweenMax.to(statCircle[i], .4, {
						strokeDashoffset: off
					});
				}
			}
		},
		
		computed: {
			ParsePercent() {
				let that = this
				return parseFloat(that.percentage)
			},
			PercentColor() {
				let that 	= this
				let Percent = that.ParsePercent
				if( Percent <= 50 ) {
					return 'green'
				} else if( Percent <= 80 ) {
					return 'orange'
				} else {
					return 'red'
				}
			},
		}
	}
</script>
<style lang="scss">
	.stat-circle {
		circle {
			&.green {
				stroke: #2ecc71;
			}
			&.orange {
				stroke: #ffa032;
			}
			&.red {
				stroke: #ff1500;
			}
			&.progress {
				fill: none;
				stroke-width: 2;
				stroke-dasharray: 51 51;
				stroke-dashoffset: -51;
				stroke-linecap: round;
			}
			&.bg {
				fill: none;
				stroke: #f1f1f1;
				stroke-width: 2;
			}
		}
		text {
			font-size: 3px;
			text-anchor: middle;
			fill: #555;
		}
	}

</style>
