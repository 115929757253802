<template>
	<div class="table-grid">
		<div class="table-responsive position-relative">
			<div :class="['overlay-blur']" v-if="ColumnBlur = 0" :style="HeightLayer">
				
				<div :class="[ ColumnBlur > 4 ? 'col-block text-center' : 'inline text-left' ]">
					<img src="/img/upgrade.png" class="upgrade-img" />
					<p class="px-5 p-upgrade">{{$t('SorryQuota')}}</p>
					<div>
						<button class="btn btn-upgrade btn-upgrade-size" @click="$bvModal.show('modal-plans')">
							<img src="/img/upgrade-icon.png" /> {{$t('UPGRADENOW')}}
						</button>
					</div>
				</div>
				
			</div>
			<table class="oml-datatable oml-destination-datatable table-md table-striped table-md-hover">
				<thead>
					<tr>
						<template v-for="(item, key) in columns">
							<th @click="sortBy(item.key)" :class="{ active: sortKey == item.key }" v-bind:key="key">
								<div style=" color: #8c8c8c; font-weight: normal; font-size: 14px; ">
									<template v-if="item.column != 'action'">
										{{ item.title }}
									</template>
									
									<template v-if="item.column == 'action'">
										<div class="text-center">{{ item.title }}</div>
									</template>
									<div style=" display: inline-block;float: right;opacity: 0.66; " v-if="item.column != 'action'">
										<i class="fa fa-sort-amount-asc" v-if="sortOrders[item.key] > 0"></i>
										<i class="fa fa-sort-amount-desc" v-else></i>
									</div>
								</div>
							</th>
						</template>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(entry, index) in filteredEmailTemp" v-bind:key="index">
						<template v-for="(item, key) in columns">
							<td v-bind:key="key" :class="[ entry.enabled == 0 ? 'column-blur' : '']">
							
								<template v-if="item.column == 'description'">
									<div class="oml-emailTemp-desc">
										<div v-snip="3" v-html="entry[item.column]" class="my-2"></div>
									</div>
								</template>
								<template v-else-if="item.column == 'time'">
									{{ entry.time | moment('timezone', workspaceTimezone, workspaceDateFormat) }}
								</template>
								<template v-else-if="item.column == 'action'">
									<div class="text-center">
										<b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" right no-caret>
											<template v-slot:button-content>
												<i class="fa fa-ellipsis-v dropdown-icon"></i>
											</template>
											<b-dropdown-item  v-b-modal="'editTemplates'" @click="editEmailTemp(entry)"><i class="fa fa-check-circle pr-1"></i> {{$t('template_edit')}}</b-dropdown-item>
											<b-dropdown-item @click="deleteEmailTemplate(entry.id)"><i class="fa fa-trash pr-1"></i> {{$t('template_delete')}}</b-dropdown-item>
										</b-dropdown>
									</div>
								</template>
								<div v-else v-html="entry[item.column]"></div>
							</td>
							
						</template>
					</tr>
					
				</tbody>
			</table>
			
			
			
			<b-modal id="editTemplates" size="lg" hide-footer @shown="test()" @hidden="close('editTemplates')" :title="$t('update_smtp_provider')">
				<div class="form-row m-0">
					<div class="form-group col-md-12 pl-0">
						<label for="name">{{$t('title')}} <b style="color:red;">*</b></label>
						<input type="text" class="form-control " v-model="editEmailTemplate.title"  placeholder="" />
						<!--:placeholder="$t('type_your_template_title_here')"-->
						<small v-if="errors.title" style="color: red;"> * {{ $t('title_required') }} </small>
					</div>
					<div class="form-group col-md-12 pl-0">
						<label for="name">{{$t('subject')}} <b style="color:red;">*</b></label>
						<input type="text" class="form-control " v-model="editEmailTemplate.subject" placeholder="" />
						<small v-if="errors.subject" style="color: red;"> * {{ $t('subject_required') }} </small>
						<!--:placeholder="$t('write_the_email_reason_here')"-->
					</div>
					<div class="form-group col-md-12 pl-0">
						<label for="name">{{$t('body')}} <b style="color:red;">*</b></label>
						<vue-editor id="template_body" :editorOptions="editorOptions" ref="editorMd" v-model="editEmailTemplate.description" class="oml-vueEditor smsEmail-templates"></vue-editor>
						<small v-if="errors.body" style="color: red;"> * {{ $t('body_required') }} </small>
					</div>
					<div class="form-group col-md-12 pl-0">
						<div class="modal-actions">
							<button class="oml-btn outlined gray-btn mr-3" @click="[cancel('edit'),$bvModal.hide('editTemplates')]">{{$t('template_cancel')}}</button>
							<button class="oml-btn" @click="updateEmailTemplate(editEmailTemplate)">{{$t('template_edit')}}</button>
						</div>
					</div>
				</div>
			</b-modal>
			
			
			<b-modal id="modal-delete-emailTemplate" centered hide-footer title="Confirmation">
				<div>
					<div class="row">
						<div class="col-12 mb-1">
							<p class="m-0" style=" color: #000; font-size: 14px; ">{{$t('voulez_vous_vraiment_supprimer')}}</p>
						</div>
					</div>
					<div class="row">
						<div class="col-12 text-right">
							<b-button size="sm" variant="success" @click="deleteEmailTemp()">
								<i class="fa fa-spinner fa-spin" v-if="loadingAction"></i> {{$t('smtp_yes_confirmation')}}
							</b-button>
						</div>
					</div>
				</div>
			</b-modal>
		</div>

		<div class="oml-smtpListing-empty">
			<div v-if="!emailTemplates.length" class="d-flex align-items-center justify-content-center flex-column">
				<img src="/img/empty.png" alt="">
				<h5>{{$t('Nothing_template_yet')}} !</h5>
				<button class="oml-btn" v-b-modal="'addTemplates'"><span>+</span> {{ $t('new_email_template') }}</button>
			</div>
		</div>
	</div>
</template>

<script>
	import { VueEditor } from "vue2-editor";
	
	export default {
		components: {
			VueEditor
		},
		props	  : {
			emailTemplates: Array,
			countTemplates: { type: Number, default: 0 },
			columns: Array,
			selectedLead: Object,
			ColumnBlur: { type: Number, default: 0 },
			countTempMethod: { type: Function },
			loadTemplates: { type: Function },
			
		},
		data() {
			var sortOrders = {}
			this.columns.forEach(function (key) {
				sortOrders[key.column] = 1
			})
			return {
				sortKey		  : '',
				sortOrders	  : sortOrders,
				searchDataData: '',
				itemIdsArray  :[],
				selected	  : [],
				allSelected	  : false,
				loadingAction: false,
				selectedDestinationId:'',
				idestination: '',
				selectedProviderId:'',
				selectedProviderObject:'',
				errors:{},
				
				
				emailTemplate:{
                    title:'',
                    subject:'',
                    description:'',
                    time:''
                },
				editEmailTemplate:{},
				selectedEmailTempId:'',
				editorOptions: {
					modules: {
						toolbar: {
							container: [
								[{'placeholder': ['[[prospect_first_name]]','[[prospect_last_name]]','[[prospect_full_name]]', '[[prospect_phone]]', '[[prospect_email]]']}],
								[{'size': ['small', false, 'large', 'huge']}],
								[{'font': []}],
								['bold', 'italic', 'underline', 'link', {'color': []}],
								[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
								[{'direction': 'rtl'}],
								[{'align': []}],
								['clean']
							],
							handlers: {
								"placeholder": function (value) {
									if (value) {
										const cursorPosition = this.quill.getSelection().index;
										this.quill.insertText(cursorPosition, value);
										this.quill.setSelection(cursorPosition + value.length);
									}
								}
							}
						}
					}
                },

			}
		},
		
		methods: {
			
			notifyMe(message, variant){
				this.$notify({
					group	: 'foo',
					// title: 'Important message',
					text	: message,
					type	: variant,
					// duration: 5000,
					// speed	: 1000
				});
				
			},
			
			doCopy: function (data) {
				let that = this
				this.$copyText(data).then(function (/* e */) {
					that.notifyMe('Copied..', 'success')
				}, function (/* e */) {
					that.notifyMe('Can not copy..', 'warn')
				})
				
			},
			
			sortBy: function (key) {
				this.sortKey = key
				this.sortOrders[key] = this.sortOrders[key] * -1
			},
			actionLead: function(entry,id,action) {
				let that = this;
				//that.leadDetails(entry.id,entry);
				that.selectedLead.id = id;
				that.selectedLead.entry = entry;
				that.$emit('actionClick', { selectedLead: that.selectedLead }, action );
			},
			/*ClassStyle(item, entry) {
				let StyleClass = ''
				
				return StyleClass
				// console.log(element)
			},*/
			
			editEmailTemp(emailTemplate){
                let that = this;
                that.editEmailTemplate = {};
                //that.editEmailTemplate = that.emailTemplates[index]
                that.editEmailTemplate.id = emailTemplate.id;
                that.editEmailTemplate.title = emailTemplate.title;
                that.editEmailTemplate.subject = emailTemplate.subject;
                that.editEmailTemplate.description = emailTemplate.description;
                that.editEmailTemplate.time = emailTemplate.time;
			},
			deleteEmailTemplate(id){
				let that = this;
				that.$bvModal.show('modal-delete-emailTemplate');
				that.selectedEmailTempId = id
			},
            deleteEmailTemp(emailTemplate,key){
                let that = this;
                let url   = '/template/'+that.selectedEmailTempId;
                let method= 'delete';
                let data  = {};
                console.log(key)
                that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
                    if(response.data.success == true){
                        var message = response.data.message;
                        that.loadTemplates("email");
                        that.notifyMe(message, 'success')
                    }
                });
				that.$bvModal.hide('modal-delete-emailTemplate');
            },
            updateEmailTemplate(editEmailTemplate) {
                let that = this;
                that.errors = {};
                let emailTemplate = editEmailTemplate;
                let url = "/template/update_template";
                let method = "patch";
                let data = [];
                if (emailTemplate.title == '') {
                    that.errors['title']  = 'Title Required';
                }
                if(emailTemplate.subject == '') {
                    that.errors['subject'] = 'Subject Required';
                }
                if(emailTemplate.description == '') {
                    that.errors['body'] = 'Body Required';
                }
                if (Object.keys(that.errors).length == 0) {
                    data   = {id: emailTemplate.id, title:emailTemplate.title,subject: emailTemplate.subject,body: emailTemplate.description};
                    that.$store
                        .dispatch("apiRequest", { url, method, data })
                        .then((response) => {
                            let feedback = response.data;
                            if (feedback.success) {
                                that.loadTemplates("email");
                                that.notifyMe(response.data.message, "success");
                                that.$bvModal.hide('editTemplates');
								
								this.countTempMethod();
                            }
                    })
                    .catch(({ response }) => {
                        that.notifyMe(response.data.message,"warn");
                    });
                }
            },
			adjustLeadInfoEditorDropdown: function () {
				var element = document.getElementsByClassName("ql-selected");
				if(element.length > 0) {
					element[0].classList.remove("ql-selected");
				}
				let placeholderPickerItems = Array.prototype.slice.call(document.querySelectorAll('.ql-placeholder .ql-picker-item'));
                if(placeholderPickerItems){
                    placeholderPickerItems.forEach(item => item.textContent = item.dataset.value);
                    const label = document.querySelector('.ql-placeholder .ql-picker-label');
                    if(label){
                        label.innerHTML = '';	
                        label.innerHTML = 'Lead infos';
                    }
                }
			},
			test: function(){
				let that = this;
				that.adjustLeadInfoEditorDropdown();
			},
			cancel(type) {
                if(type == 'add') {
                    let that = this;
                    that.emailTemplate.title        = '';
                    that.emailTemplate.subject      = '';
                    that.emailTemplate.description  = '';
                    that.emailTemplate.time         = '';
                } else {
                    let that = this;
                    console.log(type);
                    console.log(that);
                    that.loadTemplates("email");
                }
            },
			close: function(modal_type){
                if(modal_type == 'addTemplates'){
                    let that = this;
                    that.errors = {};
                    that.emailTemplate.title        = '';
                    that.emailTemplate.subject      = '';
                    that.emailTemplate.description  = '';
                    that.emailTemplate.time         = '';
                } else {
                    let that = this;
                    that.errors = {};
                }   
            },
		},
		watch: {
			itemIdsArray: function (val) {
				let that = this, items = []
				that.allSelected = false;
				
				that.emailTemplates.forEach( function(item) {
					if( val.includes(item.id) ) {
						items.push(item.id)
					}
				} )
				
				that.$emit('actionClick', items, 'itemIdsArray' );
			},
			
		},
		computed: {
			HeightLayer() {
				let columns = this.ColumnBlur > 5 ? 5 : this.ColumnBlur
				return {height: 'calc( 58px * ' + columns + ' )'}
			},
			filteredEmailTemp: function () {
				var sortKey = this.sortKey
				var filterKey = this.searchData && this.searchData.toLowerCase()
				var order = this.sortOrders[sortKey] || 1
				var emailTemplates = this.emailTemplates
				if (filterKey) {
					emailTemplates = emailTemplates.filter(function (row) {
						return Object.keys(row).some(function (key) {
							return String(row[key]).toLowerCase().indexOf(filterKey) > -1
						})
					})
				}
				if (sortKey) {
					emailTemplates = emailTemplates.slice().sort(function (a, b) {
						a = a[sortKey]
						b = b[sortKey]
						return (a === b ? 0 : a > b ? 1 : -1) * order
					})
				}
				return emailTemplates
			}
		},
		
		filters: {
			capitalize: function (str) {
				return str.charAt(0).toUpperCase() + str.slice(1)
			},
			maxLength: function (str) {
				if( str != null && str != '' ) {
					return str.trim().length > 18 ? str.trim().substring(0, 18) + "...." : str.trim()
				} else {
					return ''
				}
			}
		},
		
	}
	
</script>

<style lang="scss">

	.color-797979{
		color:#797979;
	}
	
	.width-90{
		width:90px;
	}
	
	.column-blur {
		filter: blur(8px);
	}
	
	.overlay-blur {
		position: absolute;
		top: 46px;
		width: 100%;
		z-index: 9;
		padding: 16px;
		backdrop-filter: blur(6px);
		text-align: center;
		
		align-items: center;
		display: flex;
		justify-content: center;
		>.col-block {
			width: 25em;
			.upgrade-img {
				width: 155px;
			}
		}
		>div {
			.p-upgrade {
				font-weight: 500;
				font-size: 16px;
				margin: 8px 0px;
			}
			.btn-upgrade {
				width: 15em;
				padding: 10px;
			}
		}
		>.inline {
			display: flex;
			width: 44em;
			.upgrade-img {
				height: 50px;
				margin: auto;
			}
			>div {
				margin: auto;
			}
		}
	}
	
	[md-tooltip] {
		position:relative;
	}
	[md-tooltip]:before {
		position: absolute;
		right: 0;
		top: -38px;
		background-color: #171717;
		color: #ffffff;
		height: 30px;
		line-height: 30px;
		border-radius: 5px;
		padding: 0 15px;
		content: attr(content);
		white-space: nowrap;
		display: none;
		transition:.25s ease-in-out;
		font-weight: 600;
		min-width: 85%;
		text-align: center;
	}
	[md-tooltip]:after {
		position: absolute;
		right: 45%;
		top: -8px;
		border-top: 7px solid #171717;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		content: "";
		display: none;
		transition:.25s ease-in-out;
	}
	[md-tooltip]:hover:after, [md-tooltip]:hover:before {
		display: block;
		transition:.25s ease-in-out;
	}
	
	.table-grid {
		
		.dropdown-bulk-actions-md {
			.dropdown-toggle {
				padding: 0px 15px;
			}
			.dropdown-item:hover {
				color: #fff;
			}
		}
		
		.searchData-md {
			padding: 12px 0px;
			float: right;
		}
		
		.table-md-hover {
			tbody {
				tr {
					border-bottom: 1px solid #eee;
					height: 55px;
					&:hover {
						
						td:first-child:before {
							
							height	  : 58px;
							width	  : 100%;
							position  : absolute;
							right	  : 0px;
							content	  : '';
							
							box-shadow: 0px 0px 30px -14px #404040;
							-webkit-box-shadow: 0px 0px 30px -14px #404040;
							
							-webkit-transition: all 200ms ease;
							transition: all 200ms ease;
							
							margin: -17px 0px;
							
							z-index: 0;
						}
					}
				}
			}
		}
		.table-md {
			
			tbody > tr:nth-child(2n+1) > td, tbody > tr:nth-child(2n+1) > th {
				background-color: #f8fdff;
			}
			width: 100%;
			word-break: break-all;
			
			tbody {
				
				tr {
					
					td {
						vertical-align: middle;
						max-width: 126px;
						.status-badge-md {
							color: #fff;
							margin-right: 7px;
							border-radius: 4px;
							padding: 3px 8px;
							font-style: normal;
						}
						
						.actions-btn-md {
							background-color: #e5e5e5;
							border-radius: 4px;
							padding: 4px 12px;
							font-size: 12px;
							cursor: pointer;
							color: #7b7b7b;
							text-align: center;
							max-width: 6em;
							position: relative;
						}
						.actions-btn-md:hover {
							color: #FFF;
							background-color: #377dff;
						}
						
						.details-action-md {
							.email, .phone {
								z-index: 1;
								display: block;
							}
							.email-action {
								min-width: 13.5em;
							}
							.phone-action {
								min-width: 10em;
							}
							.action {
								display: none;
								
								.send-mail, .copy, .call-event {
									background-color: #eee;
									cursor: pointer;
									display: inline-block;
									padding: 8px 6px 8px 6px;
									height: 32px;
									font-weight: 600;
									font-size: 11px;
								}
								.send-mail, .call-event {
									border-radius: 30px 0px 0px 30px;
									border-right: 1px solid #dcdcdc;
									
									a {
										color: #000;
									}
									
									.fa {
										padding-right: 4px;
										/* color: #92cf5c; */
									}
								}
								
								.copy {
									border-radius: 0px 30px 30px 0px;
								}
								
							}
							&:hover {
								.email, .phone, small {
									display: none;
								}
								.action {
									display: block;
								}
							}
						}
						
					}
					
				}
			}
			
			thead th {
				border-bottom: 1px solid #e7eaf3;
			}
			
			th {
				/* background-color: #42b983; */
				/* color: rgba(255,255,255,0.66); */
				color: #000;
				cursor: pointer;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			
			th, td {
				padding: 10px 10px;
				font-size: 13px;
			}
			
			/* th.active {
				color: #fff;
			} */
			
			th.active .arrow {
				opacity: 1;
			}
			
			.arrow {
				display: inline-block;
				vertical-align: middle;
				width: 0;
				height: 0;
				margin-left: 5px;
				opacity: 0.66;
				
				float: right;
				margin-top: 8px;
				
			}
			
			.arrow.asc {
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-bottom: 4px solid #000;
			}
			
			.arrow.dsc {
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-top: 4px solid #000;
			}
			
		}
	}
	
	.popover {
		.popover-header {
			font-size: 13px;
		}
		.popover-body {
			font-size: 12px;
		}
		.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
			border-bottom-color: #f7f7f7;
		}
	}
	
	.column_being_verified {
		.verification-progress {
			position: absolute;
			background: #bfbfbfd6;
			width: 100%;
			left: 0px;
			top: 0px;
			height: 100%;
			padding: 10px;
			text-align: center;
			font-size: 10px;
			z-index: 9;
		}
	}
	.column_being_verified {
		position: relative;
	}
	.column_not_valid {
		background-color: #ffefef !important;
	}
	.column_valid {
		background-color: #e2ffe9 !important;
	}
	
	.statusUserAction {
		option {
			background-color: #fff;
		}
	}
	
	
	
	.oml-destination-datatable{
		tr{
			&:hover{
				position:relative;
				
				td:first-child:before{
					top:0 !important;
					margin:0 !important;
					height:100% !important;
				}
			}
		}
		td{
			font-size:13px;
			vertical-align:middle;
			padding:5px 10px !important;

			.btn-group{
				button{
					padding:0;
					line-height:16px;

					i{
						padding:0;
					}
				}
			}

			.margin-auto{
				margin:0 auto;
			}

			.fw-700{
				font-weight:700;
			}

			.oml-destStatus{
				border-radius:4px;
				color:#fff;
				padding:1px 2px;
				font-size:13px;
				margin-bottom: 2px;
				font-weight:400;
				display:inline-block;

				&.qualified{
					background-color:#4caf50;
				}

				&.call-back{
					background-color:#800080;
				}
			}
		}
	}
	
	
	.oml-emailTemp-desc{
		p{
			margin-bottom:0px;
		}
	}
	
	
	
</style>
