<template>
	<div class="table-grid-mysource table-responsive">
		<table class="table-md table-striped">
				<thead>
					<tr>
						<template v-for="(item, key) in columns">
							<th @click="sortBy(item.column)" :class="{ active: sortKey == item.column, 'text-center': item.column != 'source' }" v-if="item.enable" v-bind:key="key">
								<div style=" color: #8c8c8c; font-weight: normal; font-size: 14px; ">
									{{ item.title }}
                                    <i class="fa fa-sort-amount-asc" v-if="sortOrders[item.column] > 0"></i>
                                    <i class="fa fa-sort-amount-desc" v-else></i>
								</div>
							</th>
						</template>
					</tr>
				</thead>
                <tbody>
                    <tr v-for="(entry, id) in filteredHeroes" v-bind:key="id">
                        <td v-for="(item, key) in columns" v-bind:key="key">
                            <div v-if="item.column == 'source'" class="d-flex align-items-center">
                                <img :src="`/img/${arrayType[entry.type]}`" style="width: 35px; height:35px;margin-right: 14px; border-radius: 3px;" class="c-pointer" />
                                <div>
                                    <div class="not-bold" style="padding-top: 1px;" :title="entry[item.column]">
                                        <span class="c-pointer">
											{{ entry[item.column].replace(' - ' + entry['created_at'] ,'').replace('-' + entry['created_at'] ,'') | MaxLength }}
										</span>
                                    </div>
                                    <span style="font-size: 11px;color: #9599a3;margin-top: 7px;display: inline-block;" class="c-pointer">
                                        {{ $t('Publishedon') }} : 
										
										<span>
											{{ entry['created_at'] | moment('timezone', workspaceTimezone, workspaceDateFormat) }}
										</span>
                                    </span>
                                </div>
                            </div>
                            <div v-else-if="item.column == 'processingRate' || item.column == 'qualificationRate'" class="text-center">
                                <span v-if="entry[item.column] < 10" class="text-danger">{{ entry[item.column] }} %</span>
                                <span v-else-if="entry[item.column] < 80" class="text-warning">{{ entry[item.column] }} %</span>
                                <span v-else class="text-success">{{ entry[item.column] }} %</span>
                            </div>
                            <center class="not-bold" v-else v-html="entry[item.column]"></center>
                        </td>
                    </tr>
                    <tr v-if="filteredHeroes.length == 0">
                        <td :colspan="columns.length" style="font-weight: unset;"><center>{{ $t('NoSource') }}</center></td>
                    </tr>
                </tbody>
            </table>
		</div>
</template>

<script>	
	export default {
		props: {
			topSources	: Array,
			columns		: Array,
			searchQuery : String
		},
		data() {
			var sortOrders = {}
			this.columns.forEach(function (key) {
				sortOrders[key.column] = 1
			})
			return {
				arrayType:{
					"csv"				:'Integration/sources/excel.png',
					"xlsx"				:'Integration/sources/excel.png',
					"zapier_app"		:'oml-icon-zapier.png',
					"facebook_leadgen"	:'Integration/sources/oml-icon-facebook_leadgen.png',
					"gravityforms"		:'Integration/sources/gravity-forms.png',
					"gravity"			:'Integration/sources/gravity-forms.png',
					"manually_added"	:'Integration/sources/manually_added.png',
					"google_sheet"		:'Integration/sources/google-sheets.png',
					"web_site"			:'Integration/sources/web_site.png',
					"demo_data"			:'Integration/sources/demo-data.jpg',
					"webhook"			:'Integration/sources/webhook.png',
					"googleLead"		:'Integration/sources/google-lead-form.png',
					"clickFunnels"		:'Integration/sources/clickFunnels.png',
					"landingi"			:'Integration/sources/landingi.jpg',
				},
				sortKey: '',
				sortOrders: sortOrders,
				loadingAction : false,
				selectedSource: {
					id		: '',
					element	: '',
					source	: '',
				},
			}
		},
		methods: {
			sortBy(key) {
				this.sortKey = key
				this.sortOrders[key] = this.sortOrders[key] * -1
			}
		},
		computed: {
			filteredHeroes() {
				var sortKey = this.sortKey
				var filterKey = this.searchQuery && this.searchQuery.toLowerCase()
				var order = this.sortOrders[sortKey] || 1
				var topSources = this.topSources
				if (filterKey) {
					topSources = topSources.filter(function (row) {
						return Object.keys(row).some(function (key) {
							return String(row[key]).toLowerCase().indexOf(filterKey) > -1
						})
					})
				}
				if (sortKey) {
					topSources = topSources.slice().sort(function (a, b) {
						a = a[sortKey]
						b = b[sortKey]
						return (a === b ? 0 : a > b ? 1 : -1) * order
					})
				}
				return topSources
			}
		},
		filters: {
			MaxLength: function (str) {
				if( str != null && str != '' ) {
					return str.trim().length > 45 ? str.trim().substring(0, 45) + "...." : str.trim()
				} else {
					return ''
				}
			}
		}
	}
</script>

<style lang="scss">
	/* .table-grid .table-md-hover tbody tr:hover td:first-child::before {
        box-shadow: none;
        -webkit-box-shadow: none;
    } */
    .table-grid-mysource .table-md{
        border-top: none;
    }
    .table-grid-mysource .table-md tbody tr {
        border-bottom: none;
        border-top: 1px solid #eee;
    }
    .table-striped tbody tr:nth-of-type(2n+1) {
        background-color: #f8fdff;
    }
    .not-bold {
        font-weight: normal;
    }
</style>
