<template>
	<div class="table-grid">
		<div class="table-responsive position-relative">
			<div :class="['overlay-blur']" v-if="ColumnBlur > 0" :style="HeightLayer">
				
				<div :class="[ ColumnBlur > 4 ? 'col-block text-center' : 'inline text-left' ]">
					<img src="/img/upgrade.png" class="upgrade-img" />
					<p class="px-5 p-upgrade">{{$t('SorryQuota')}}</p>
					<!--div><button @click="SeePlans" class="btn btn-outline-upgrade btn-upgrade-size">SEE PRICING & PLANS</button></div-->
					<div>
						<button class="btn btn-upgrade btn-upgrade-size" @click="$bvModal.show('modal-plans')">
							<img src="/img/upgrade-icon.png" /> {{$t('UPGRADENOW')}}
						</button>
					</div>
				</div>
				
			</div>
			<table class="oml-datatable table-md table-striped table-md-hover"><!-- table-hover table -->
				<thead>
					<tr>
						<th>
							<!--input type="checkbox" @click="selectAll" v-model="allSelected" /-->
							<div class="custom-control custom-checkbox mr-sm-2">
								<input type="checkbox" class="custom-control-input" id="selectAllItems" @click="selectAll" v-model="allSelected">
								<label class="custom-control-label" for="selectAllItems"></label>
							</div>
						</th>
						<template v-for="(item, key) in columns">
							<th @click="sortBy(item.column)" :class="{ active: sortKey == item.column }" v-if="item.enable" v-bind:key="key">
								<div style=" color: #8c8c8c; font-weight: normal; font-size: 14px; "> <!--   style="display: flex;" -->
									{{ item.title }}
									
									<span v-if="item.column == 'score'" id="popover-target-fa">
										<i class="fa fa-info-circle"></i>
										<b-popover target="popover-target-fa" triggers="hover" placement="top">
											<template v-slot:title>{{ $t('WhatIsLeadScoring')}}</template>
											<span style='font-weight: 800; font-size: 1.4em;' class='upgrade_verify'>*</span> <span  class=''>{{ $t('leadScoringMessage1')}}</span><br>
											<span style='font-weight: 800; font-size: 1.4em;' class='upgrade_verify'>*</span> <span  class=''>{{ $t('leadScoringMessage2')}} <router-link :to="{path: 'settings/scoring', params: {page: 'scoring'}}" target="_blank">{{$t('clickingHere')}}</router-link> </span><br>
										</b-popover>
									</span>
									
									<!--div :class="[sortOrders[item.column] > 0 ? 'asc' : 'dsc', 'arrow']"></div-->
									<div style=" display: inline-block;float: right;opacity: 0.66; " v-if="item.column != 'details'">
										<i class="fa fa-sort-amount-asc" v-if="sortOrders[item.column] > 0"></i>
										<i class="fa fa-sort-amount-desc" v-else></i>
									</div>
								</div>
							</th>
						</template>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(entry, id) in filteredLeads" v-bind:key="id">
						<td :class="[ entry.enabled == 0 ? 'column-blur col-50' : 'col-50' ]">
							<!--input type="checkbox" v-model="itemIdsArray" :value="entry.id" /-->
							<div class="custom-control custom-checkbox mr-sm-2">
								<input type="checkbox" class="custom-control-input" :id="`selectItems-${entry.id}`" v-model="itemIdsArray" :value="entry.id">
								<label class="custom-control-label" :for="`selectItems-${entry.id}`"></label>
							</div>
						</td>
						
						<template v-for="(item, key) in columns">
							
							<td v-if="item.enable" v-bind:key="key" :class="[ entry.enabled == 0 ? 'column-blur' : '', ClassStyle(item, entry), item.column == 'full_name' ? 'min-w-140' : '',item.column == 'email' ? 'min-w-135 max-w-135' : '',item.column == 'personal_phone' ? 'min-w-135 max-w-135' : '']">
								<!-- {{entry[key]}} -->
								<div v-if="item.column == 'details'">	
									<div v-if="!$store.state.active || ($store.state.active && 'detail lead' in $store.state.permissions)" class="actions-btn-md" @click="[actionLead(entry, id, 'view'), $emit('update:activeDetailsLead', true)]"><i class="icon icon-eye"></i> {{$t('View')}}</div>
								</div>
								<div v-else-if="item.column == 'status'" class="">
									<span v-if="entry[item.column].length > 16" :content="entry[item.column]" md-tooltip="hover me"   v-html="entry[item.column].substring(0, 16) + '...'" class="status-badge-md" :style="{ backgroundColor: entry['color'] }"></span>
									<span v-else    v-html="entry[item.column]" class="status-badge-md" :style="{ backgroundColor: entry['color'] }"></span>
								</div>
								
								<!--template v-else-if="item.column == 'full_name'">
									<div :title="entry['full_name']">{{ entry['full_name'] | maxLength }}</div>
									<span v-if="entry['duration'] == 'New' || entry['duration'] == 'Nouveau '" class="badge badge-success">{{$t('New')}}</span>
									<span v-else v-html="entry['duration']" style="color:#989898;"></span>
									&nbsp;
									<span v-if="entry.is_duplicate" class="badge badge-danger">{{$t('duplicate')}}</span>
								</template-->

								<template v-else-if="item.column == 'full_name'">
									<div :class="['fullname-col',entry['duration'] == 'New' || entry['duration'] == 'Nouveau ' ? '' : 'without-new',entry['is_duplicate'] == 1 ? '' : 'without-duplicate']">
										<div class="fullnameCol-infos">
											<h6>{{ entry['full_name'] | maxLength }}</h6>
											<span v-if="entry['duration'] == 'New'" class="fullnameCol-delay" v-html="diff_hours(entry['generatedOn'])"></span>
											<span v-else class="fullnameCol-delay" v-html="diff_hours(entry['generatedOn'])"></span>
										</div>
										<div class="fullnameCol-status">
											<span v-if="entry['duration'] == 'New' || entry['duration'] == 'Nouveau '" class="fullname-new">{{$t('New')}}</span>
											<span v-if="entry['is_duplicate'] == 1" class="fullname-duplicate">{{$t('duplicate')}}</span>
										</div>
									</div>
								</template>
								
								
								<template v-else-if="item.column == 'email'">
									<div class="details-action-md">
										<div class="d-flex">
											<div class="email d-flex align-items-center justify-content-between w-100">
												<span class="ws-ellipsis">{{ entry[item.column] | maxLength }}</span>
												<div v-if="entry['email_verified'] == 0 && entry[item.column] != ''"></div>
												<small v-else-if="(entry['email_verified'] == 1 || entry['email_verified'] == 3) && entry[item.column] != ''" class="small-md text-danger"><i class="ri-close-line fs-18 ml-2"></i></small>
												<small v-else-if="entry['email_verified'] == 2 && entry[item.column] != ''" class="small-md text-success lh-9"><i class="fa fa-check-square-o fs-13 ml-2"></i></small>
											</div>
											<div class="action email-action" :content="entry[item.column]" md-tooltip="hover me">
												<!--div class="send-mail" @click="actionLead(entry, id, 'sendEmail')">
													<i class="fa fa-envelope"></i>
													<span>Send Message</span>
												</div-->
												<a @click="[actionLead(entry, id, 'sendEmail'), $emit('update:activeDetailsLead', true)]" class="send-mail" style="color: #33363a;">
													<i class="fa fa-envelope"></i>
													<span>{{ $t('sendEmail')}}</span>
												</a>
												<div class="copy" @click="doCopy(entry[item.column])">{{ $t('Copy') }}</div>
											</div>
										</div>
										<div v-if="entry['email_verified'] == 0 && entry[item.column] != ''" class="verification-progress">
											<i class="fa fa-spinner fa-spin" style="font-size: 22px;"></i> <div>{{ $t('verificationInProgress') }} ..</div>
										</div>
									</div>
									<div  class="verification-progress" v-if="demoData == true && demoDataSeen == true">
										<i class="fa fa-spinner fa-spin" style="font-size: 22px;"></i> <div></div>
									</div>
								</template>
								
								<template v-else-if="item.column == 'personal_phone'">
									<div class="details-action-md">
										<div class="d-flex">
											<div class="phone d-flex align-items-center justify-content-between w-100">
												<span class="ws-ellipsis">{{ entry[item.column] }}</span>
												<div v-if="entry['phone_verified'] == 0 && entry[item.column] != ''"></div>
												<small v-else-if="(entry['phone_verified'] == 1 || entry['phone_verified'] == 3) && entry[item.column] != ''" class="small-md text-danger"><i class="ri-close-line fs-18 ml-2"></i></small>
												<small v-else-if="entry['phone_verified'] == 2 && entry[item.column] != ''" class="small-md text-success lh-9"><i class="fa fa-check-square-o fs-13 ml-2"></i></small>
											</div>
											<div class="action phone-action" :content="entry[item.column]" md-tooltip="hover me">
												<div class="call-event">
													<a :href="`tel:${entry[item.column]}`"> <!-- +1 (631) 000 0000 -->
														<i class="fa fa-phone"></i>
														<span>{{ $t('Call')}}</span>
													</a>
												</div>
												<div class="copy" @click="doCopy(entry[item.column])">{{ $t('Copy') }}</div>
											</div>
										</div>
										<!--small style="font-size: 11px;" v-if="entry[item.column] == ''" class="text-danger"><i class="fa fa-times-circle"></i> Not Exist</small-->
										<div v-if="entry['phone_verified'] == 0 && entry[item.column] != ''" class="verification-progress">
											<i class="fa fa-spinner fa-spin" style="font-size: 22px;"></i> <div>{{ $t('verificationInProgress') }} ..</div>
										</div>
									</div>
									<div  class="verification-progress" v-if="demoData == true && demoDataSeen == true">
										<i class="fa fa-spinner fa-spin" style="font-size: 22px;"></i> <div></div>
									</div>
								</template>
								<template v-else-if="item.column == 'generatedOn'">
									{{ entry[item.column] | moment('timezone', workspaceTimezone, workspaceDateFormat) }}
								</template>
								<div v-else v-html="entry[item.column]"></div>
							</td>
							
						</template>
					</tr>
					
					<tr v-if="filteredLeads.length == 0">
						<td :colspan="columns.length"><center>{{ $t('NoLeadToDisplay') }}</center></td>
					</tr>
					
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
	// register the grid component
	import moment from 'moment'

	export default {
		components: {
			//moment
		},
		props	  : {
			myLeads		: Array,
			columns		: Array,
			// totalLead: Number,
			selectedLead: Object,
			demoData: Boolean,
			demoDataSeen: Boolean,
			
			ColumnBlur	: { type: Number, default: 0 },
		},
		data() {
			var sortOrders = {}
			this.columns.forEach(function (key) {
				sortOrders[key.column] = 1
			})
			return {
				sortKey		: '',
				sortOrders	: sortOrders,
				searchDataData: '',
				itemIdsArray:[],
				
				selected	: [],
				allSelected	: false,
				
			}
		},
		
		methods: {
			
			notifyMe(message, variant){
				this.$notify({
					group	: 'foo',
					// title: 'Important message',
					text	: message,
					type	: variant,
					// duration: 5000,
					// speed	: 1000
				});
				
			},
			
			doCopy: function (data) {
				let that = this
				this.$copyText(data).then(function (/* e */) {
					that.notifyMe('Copied..', 'success')
				}, function (/* e */) {
					that.notifyMe('Can not copy..', 'warn')
				})
				
			},
			
			sortBy: function (key) {
				this.sortKey = key
				this.sortOrders[key] = this.sortOrders[key] * -1
			},
			
			selectAll: function() {
				let that = this
				that.itemIdsArray = [];
				if (!that.allSelected) {
					
					that.filteredLeads.forEach( function( row ) {
						that.itemIdsArray.push(row.id);
					} );
				}
				var itemIdsArray = that.itemIdsArray
				this.$emit('actionClick', itemIdsArray, 'itemIdsArray' );
				
			},
			actionLead: function(entry,id,action) {
				let that = this;
				//that.leadDetails(entry.id,entry);
				that.selectedLead.id = id;
				that.selectedLead.entry = entry;
				that.$emit('actionClick', { selectedLead: that.selectedLead }, action );
			},
			ClassStyle(item, entry) {
				let StyleClass = ''
				if ( item.column == 'email' ) {
					if (entry['email_verified'] == 0) {
						StyleClass = 'column_being_verified'
					} else if (entry['email_verified'] == 1 || entry['email_verified'] == 3) {
						StyleClass = 'column_not_valid'
					} else if (entry['email_verified'] == 2) {
						StyleClass = 'column_valid'
					}
				} else if ( item.column == 'personal_phone' ) {
					if (entry['phone_verified'] == 0) {
						StyleClass = 'column_being_verified'
					} else if (entry['phone_verified'] == 1 || entry['phone_verified'] == 3) {
						StyleClass = 'column_not_valid'
					} else if (entry['phone_verified'] == 2) {
						StyleClass = 'column_valid'
					}
				}
				return StyleClass
				// console.log(element)
			},
			diff_hours(date_gene){
				let date_now = new Date();
				let start_time = moment(date_gene, 'YYYY-MM-DD hh:mm:ss');
				let end_time = moment(date_now, 'DD-MM-YYYY hh:mm:ss');

				let hoursDiff = end_time.diff(start_time, 'hours');

				let delay = ''
				if(hoursDiff <= 24){
					if(hoursDiff == 0){
						delay = this.$t('now_lead')
					}else{
						delay = hoursDiff + ' Hours ago'
					}
				}else if (hoursDiff > 24){
					delay = end_time.diff(start_time, 'days') + ' Days ago'
				}else if (hoursDiff > 720){
					delay = end_time.diff(start_time, 'months') + ' Months ago'
				}
				return delay;
				
			}
		},
		watch: {
			itemIdsArray: function (val) {
				let that = this, items = []
				that.allSelected = false;
				
				that.myLeads.forEach( function(item) {
					if( val.includes(item.id) ) {
						items.push(item.id)
					}
				} )
				
				that.$emit('actionClick', items, 'itemIdsArray' );
			},
			
		},
		computed: {
			HeightLayer() {
				let columns = this.ColumnBlur > 5 ? 5 : this.ColumnBlur
				return {height: 'calc( 58px * ' + columns + ' )'}
			},
			filteredLeads: function () {
				var sortKey = this.sortKey
				var filterKey = this.searchData && this.searchData.toLowerCase()
				var order = this.sortOrders[sortKey] || 1
				var myLeads = this.myLeads
				if (filterKey) {
					myLeads = myLeads.filter(function (row) {
						return Object.keys(row).some(function (key) {
							return String(row[key]).toLowerCase().indexOf(filterKey) > -1
						})
					})
				}
				if (sortKey) {
					myLeads = myLeads.slice().sort(function (a, b) {
						a = a[sortKey]
						b = b[sortKey]
						return (a === b ? 0 : a > b ? 1 : -1) * order
					})
				}
				return myLeads
			}
		},
		
		filters: {
			capitalize: function (str) {
				return str.charAt(0).toUpperCase() + str.slice(1)
			},
			maxLength: function (str) {
				if( str != null && str != '' ) {
					return str.trim().length > 18 ? str.trim().substring(0, 18) + "...." : str.trim()
				} else {
					return ''
				}
			}
		},
		
	}
	
</script>

<style lang="scss">

	.table-grid{
		min-height:100vh;
		position:relative;
	}
	
	.column-blur{
		filter: blur(8px);
	}
	
	.overlay-blur {
		position: absolute;
		top: 46px;
		width: 100%;
		z-index: 9;
		padding: 16px;
		backdrop-filter: blur(6px);
		text-align: center;
		
		align-items: center;
		display: flex;
		justify-content: center;
		>.col-block {
			width: 25em;
			.upgrade-img {
				width: 155px;
			}
		}
		>div {
			.p-upgrade {
				font-weight: 500;
				font-size: 16px;
				margin: 8px 0px;
			}
			.btn-upgrade {
				width: 15em;
				padding: 10px;
			}
		}
		>.inline {
			display: flex;
			width: 44em;
			.upgrade-img {
				height: 50px;
				margin: auto;
			}
			>div {
				margin: auto;
			}
		}
	}
	
	[md-tooltip] {
		position:relative;
	}
	[md-tooltip]:before {
		position: absolute;
		right: 0;
		top: -38px;
		background-color: #171717;
		color: #ffffff;
		height: 30px;
		line-height: 30px;
		border-radius: 5px;
		padding: 0 15px;
		content: attr(content);
		white-space: nowrap;
		display: none;
		transition:.25s ease-in-out;
		font-weight: 600;
		min-width: 85%;
		text-align: center;
	}
	[md-tooltip]:after {
		position: absolute;
		right: 45%;
		top: -8px;
		border-top: 7px solid #171717;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		content: "";
		display: none;
		transition:.25s ease-in-out;
	}
	[md-tooltip]:hover:after, [md-tooltip]:hover:before {
		display: block;
		transition:.25s ease-in-out;
	}
	
	.table-grid {
		
		.dropdown-bulk-actions-md {
			.dropdown-toggle {
				padding: 0px 15px;
			}
			.dropdown-item:hover {
				color: #fff;
			}
		}
		
		.searchData-md {
			padding: 12px 0px;
			float: right;
		}
		
		.table-md-hover {
			tbody {
				tr {
					border-bottom: 1px solid #eee;
					height: 35px !important;
					&:hover {
						
						td:first-child:before {
							
							height	  : 58px;
							width	  : 100%;
							position  : absolute;
							right	  : 0px;
							content	  : '';
							
							box-shadow: 0px 0px 30px -14px #404040;
							-webkit-box-shadow: 0px 0px 30px -14px #404040;
							
							-webkit-transition: all 200ms ease;
							transition: all 200ms ease;
							
							margin: -17px 0px;
							
							z-index: 0;
						}
					}
				}
			}
		}
		.table-md {
			
			tbody > tr:nth-child(2n+1) > td, tbody > tr:nth-child(2n+1) > th {
				background-color: #f8fdff;
			}
			width: 100%;
			word-break: break-all;
			
			tbody {
				
				tr {
					
					td {
						vertical-align: middle;
						max-width: 126px;
						padding:0 10px !important;
						position:relative;
						
						.status-badge-md {
							color: #fff;
							margin-right: 7px;
							border-radius: 4px;
							padding: 3px 8px;
							font-style: normal;
							display:inline-block;
						}
						
						.actions-btn-md {
							background-color: #F0F0F0 !important;
							border-radius: 4px !important;
							padding: 0px 5px !important;
							font-size: 12px !important;
							cursor: pointer !important;
							color: #1D2841 !important;
							text-align: center !important;
							max-width: 65px !important;
							height: 30px !important;
							width:auto !important;
							position: relative !important;
							display:flex;
							align-items:center;

							i{
								font-size:14px;
								margin-right:5px;
							}
						}
						.actions-btn-md:hover {
							color: #FFF;
							background-color: #377dff;
						}
						
						.details-action-md {
							position:relative;

							.email, .phone {
								z-index: 1;
								display: block;
							}
							.email-action {
								min-width: 13.5em;
							}
							.phone-action {
								min-width: 10em;
							}
							.action {
								display: none;
								position:absolute;
								top:0;
								left:0;
								right:0;
								bottom:0;
								width:100px;
								height:30px;
								margin:auto;
								z-index:1;
								
								.send-mail, .copy, .call-event {
									background-color: #eee;
									cursor: pointer;
									display: inline-block;
									padding: 8px 6px 8px 6px;
									height: 32px;
									font-weight: 600;
									font-size: 11px;
								}
								.send-mail, .call-event {
									border-radius: 30px 0px 0px 30px;
									border-right: 1px solid #dcdcdc;
									
									a {
										color: #000;
									}
									
									.fa {
										padding-right: 4px;
										/* color: #92cf5c; */
									}
								}
								
								.copy {
									border-radius: 0px 30px 30px 0px;
								}
								
							}
							&:hover {
								.email, .phone, small {
									display: none;
								}
								.action {
									display: block;
								}
							}
						}
						
					}
					
				}
			}
			
			thead th {
				border-bottom: 1px solid #e7eaf3;
			}
			
			th {
				/* background-color: #42b983; */
				/* color: rgba(255,255,255,0.66); */
				color: #000;
				cursor: pointer;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			
			th, td {
				padding: 10px 10px;
				font-size: 13px;
			}
			
			/* th.active {
				color: #fff;
			} */
			
			th.active .arrow {
				opacity: 1;
			}
			
			.arrow {
				display: inline-block;
				vertical-align: middle;
				width: 0;
				height: 0;
				margin-left: 5px;
				opacity: 0.66;
				
				float: right;
				margin-top: 8px;
				
			}
			
			.arrow.asc {
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-bottom: 4px solid #000;
			}
			
			.arrow.dsc {
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-top: 4px solid #000;
			}
			
		}
	}
	
	.popover {
		.popover-header {
			font-size: 13px;
		}
		.popover-body {
			font-size: 12px;
		}
		.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
			border-bottom-color: #f7f7f7;
		}
	}
	
	tbody > tr:nth-child(2n+1) > td .verification-progress{
		background-color: #f8fdff;
	}
	.verification-progress{
		position: absolute;
		background: #fff;
		width: 100%;
		left: 0px;
		top: 0px;
		height: 100%;
		padding: 10px;
		text-align: center;
		font-size: 10px;
		z-index: 9;

		animation: cssAnimation 0s 1s forwards;
		visibility: visible;

		i{
			font-size:16px !important;
		}
	}

	@keyframes cssAnimation {
		to   { visibility: hidden; }
	}

	.column_being_verified {
		position: relative;
	}
	.column_not_valid {
		background-color: #ffefef !important;
	}
	.column_valid {
		background-color: #e2ffe9 !important;
	}
	
	.statusUserAction {
		option {
			background-color: #fff;
		}
	}


	.fullname-col{
		display:flex;
		align-items:center;
		justify-content:space-between;
		margin-top: 7px;
	
		.fullnameCol-infos{
			width:calc(100% - 95px);
			
			h6{
				font-size:13px;
				font-weight:400;
				margin-bottom:0px;
				white-space: nowrap;
				overflow: hidden !important;
				text-overflow: ellipsis;
				line-height:11px;
			}
			
			span{
				&.fullnameCol-delay{
					font-size:11px;
					color:#b5b5b5;
					white-space:nowrap;
					line-height:7px;
				}
			}
		}
		
		.fullnameCol-status{
			margin-left:4px;
			width:95px;
			display:flex;
			align-items:center;
			justify-content:flex-end;
			
			span{
				padding:0px 3px;
				border-radius:2px;
				margin:0 3px;
				white-space:nowrap;
				font-size:10px;
				
				&.fullname-new{
					color:#27a744;
					border:1px solid #27a744;
				}
				
				&.fullname-duplicate{
					color:#dc3545;
					border:1px solid #dc3545;
				}
			}
		}

		&.without-new{
			.fullnameCol-infos{
				width:calc(100% - 62px);
			}
			.fullnameCol-status{
				width:50px;
			}
		}

		&.without-duplicate{
			.fullnameCol-infos{
				width:calc(100% - 32px);
			}
			.fullnameCol-status{
				width:32px;
			}
		}

		&.without-duplicate.without-new{
			.fullnameCol-infos{
				width:100%;
			}
			.fullnameCol-status{
				width:0px;
			}
		}
	}

	.col-50{
		width:50px !important;
	}
	
</style>
