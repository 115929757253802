<template>
	<div class="table-grid">
		<div class="table-responsive position-relative">
			<div :class="['overlay-blur']" v-if="ColumnBlur = 0" :style="HeightLayer">
				
				<div :class="[ ColumnBlur > 4 ? 'col-block text-center' : 'inline text-left' ]">
					<img src="/img/upgrade.png" class="upgrade-img" />
					<p class="px-5 p-upgrade">{{$t('SorryQuota')}}</p>
					<!--div><button @click="SeePlans" class="btn btn-outline-upgrade btn-upgrade-size">SEE PRICING & PLANS</button></div-->
					<div>
						<button class="btn btn-upgrade btn-upgrade-size" @click="$bvModal.show('modal-plans')">
							<img src="/img/upgrade-icon.png" /> {{$t('UPGRADENOW')}}
						</button>
					</div>
				</div>
				
			</div>
			<table class="oml-datatable oml-destination-datatable table-md table-striped table-md-hover">
				<thead>
					<tr>
						<template v-for="(item, key) in columns">
							<th @click="sortBy(item.column)" :class="{ active: sortKey == item.column }" v-if="item.enable" v-bind:key="key">
								<div style=" color: #8c8c8c; font-weight: normal; font-size: 14px; ">

									<template v-if="item.column != 'details'">
										{{ item.title }}
									</template>
									
									<div v-if="item.column == 'details'" style="text-align:center;">
										{{ item.title }}
									</div>
									
									<span v-if="item.column == 'score'" id="popover-target-fa">
										<i class="fa fa-info-circle"></i>
									</span>

									<div style=" display: inline-block;float: right;opacity: 0.66; " v-if="item.column != 'details'">
										<i class="fa fa-sort-amount-asc" v-if="sortOrders[item.column] > 0"></i>
										<i class="fa fa-sort-amount-desc" v-else></i>
									</div>
								</div>
							</th>
						</template>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(entry, id) in filteredDestinations" v-bind:key="id">
						<template v-for="(item, key) in columns">
							
							<td v-if="item.enable" v-bind:key="key" :class="[ entry.enabled == 0 ? 'column-blur' : '', ClassStyle(item, entry), item.column == 'leads' ? 'width-120' : '', item.column == 'destination_name' ? 'width-250' : '', item.column == 'active' ? 'width-100' : '', item.column == 'updated_at' ? 'width-200 text-center' : '' ]">
								<template v-if="item.column == 'leads'">
									<div class="text-center fw-700 color-797979" v-html="entry[item.column]"></div>
								</template>
								
								<template v-else-if="item.column == 'status'">
									<span v-for="(state, index) in entry[item.column]" :key="index" class="oml-destStatus" :style="{ color: state.color }">{{state.status}} <b v-if="index != Object.keys(entry[item.column]).length - 1" style="color:#797979;">,</b></span> 
								</template>
								
								<template v-else-if="item.column == 'active'">
									<div class="d-flex align-items-center justify-content-center">
										<div class="text-center">
											<div class="custom-control custom-switch b-custom-control-lg" @click="[saveActive(entry),entry.active = !entry.active]">
												<input type="checkbox" class="custom-control-input"  v-model="entry.active">
												<label class="custom-control-label" ></label>
											</div>
										</div>
									</div>
								</template>
								
								<template v-else-if="item.column == 'details'">
									<div class="text-center">
										<b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" right no-caret class="margin-auto">
											<template v-slot:button-content>
												<i class="fa fa-ellipsis-v dropdown-icon"></i>
											</template>
											
											<b-dropdown-item @click="eventCallBack(entry.id,entry.plateform)"><i class="fa fa-check-circle pr-1"></i> {{$t('Edit')}} </b-dropdown-item>
											
											<b-dropdown-item @click="deleteEvent(entry.id)"><i class="fa fa-trash pr-1"></i> {{$t('Delete')}}</b-dropdown-item>
										</b-dropdown>
									</div>

								</template>
								<div v-else v-html="entry[item.column]"></div>
							</td>
							
						</template>
					</tr>
					
				</tbody>
			</table>
			
			<b-modal id="modal-delete-destination" centered hide-footer title="Confirmation"> <!-- size="sm" -->
				<div>
					<div class="row">
						<div class="col-12 mb-1">
							<p class="m-0" style=" color: #000; font-size: 14px; ">{{$t('QuestionContinue')}}</p>
						</div>
					</div>
					<div class="row">
						<div class="col-12 text-right">
							<b-button size="sm" variant="success" @click="deleteDestination()">
								<i class="fa fa-spinner fa-spin" v-if="loadingAction"></i> {{$t('YesDeleteIt')}}
							</b-button>
						</div>
					</div>
				</div>
			</b-modal>
		</div>
		<div class="oml-destinationsListing-empty" >
			<!--v-if="!items.length"-->
			<div v-if="!myDestinations.length" class="d-flex align-items-center justify-content-center flex-column">
				<img src="/img/empty.png" alt="">
				<h5>{{$t('Nothing_here_yet')}} !</h5>
				<!-- <p>You can create an email template by clicking on this button</p> -->
				<router-link to="integrations"><button v-b-modal="'addTemplates'" class="oml-btn"><span>+</span> {{ $t('New_Destinations') }}</button></router-link>
			</div>
		</div>
	</div>
</template>

<script>
	// register the grid component
	export default {
		components: {
			
		},
		props	  : {
			myDestinations: Array,
			columns: Array,
			selectedLead: Object,
			ColumnBlur: { type: Number, default: 0 },
		},
		data() {
			var sortOrders = {}
			this.columns.forEach(function (key) {
				sortOrders[key.column] = 1
			})
			return {
				sortKey		  : '',
				sortOrders	  : sortOrders,
				searchDataData: '',
				itemIdsArray  :[],
				selected	  : [],
				allSelected	  : false,
				loadingAction: false,
				selectedDestinationId:'',
				idestination: '',
			}
		},
		
		methods: {
			
			notifyMe(message, variant){
				this.$notify({
					group	: 'foo',
					// title: 'Important message',
					text	: message,
					type	: variant,
					// duration: 5000,
					// speed	: 1000
				});
				
			},
			
			doCopy: function (data) {
				let that = this
				this.$copyText(data).then(function (/* e */) {
					that.notifyMe('Copied..', 'success')
				}, function (/* e */) {
					that.notifyMe('Can not copy..', 'warn')
				})
				
			},
			
			sortBy: function (key) {
				this.sortKey = key
				this.sortOrders[key] = this.sortOrders[key] * -1
			},
			
			selectAll: function() {
				let that = this
				that.itemIdsArray = [];
				if (!that.allSelected) {
					
					that.filteredDestinations.forEach( function( row ) {
						that.itemIdsArray.push(row.id);
					} );
				}
				var itemIdsArray = that.itemIdsArray
				this.$emit('actionClick', itemIdsArray, 'itemIdsArray' );
				
			},
			actionLead: function(entry,id,action) {
				let that = this;
				//that.leadDetails(entry.id,entry);
				that.selectedLead.id = id;
				that.selectedLead.entry = entry;
				that.$emit('actionClick', { selectedLead: that.selectedLead }, action );
			},
			ClassStyle(item, entry) {
				let StyleClass = ''
				if ( item.column == 'email' ) {
					if (entry['email_verified'] == 0) {
						StyleClass = 'column_being_verified'
					} else if (entry['email_verified'] == 1 || entry['email_verified'] == 3) {
						StyleClass = 'column_not_valid'
					} else if (entry['email_verified'] == 2) {
						StyleClass = 'column_valid'
					}
				} else if ( item.column == 'personal_phone' ) {
					if (entry['phone_verified'] == 0) {
						StyleClass = 'column_being_verified'
					} else if (entry['phone_verified'] == 1 || entry['phone_verified'] == 3) {
						StyleClass = 'column_not_valid'
					} else if (entry['phone_verified'] == 2) {
						StyleClass = 'column_valid'
					}
				}
				return StyleClass
				// console.log(element)
			},
			
			eventCallBack(id,plateform) {
				let that = this
				
				if(plateform == 'mailjet'){
					that.$router.push({path: 'destination/edit-mailjet/'+id})
				}else if(plateform == 'mailchimp'){
					that.$router.push({path: 'destination/edit-mailchimp/'+id})
				}else if(plateform == 'sendinblue'){
					that.$router.push({path: 'destination/edit-sendinblue/'+id})
				}else{
					that.$router.push({path: 'destination/edit/'+id})
				}
				
			},
			getDestinations() {
				let that = this
				let url   = '/webhook/destinations'
				let method= 'POST'
				let data  = {}
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					this.myDestinations = response.data.destinations;
					this.Filters     = response.data.types;
				}).catch(( {response} ) => {
					console.log(response)
				})
			},
			deleteEvent(id){
				let that = this;
				that.$bvModal.show('modal-delete-destination');
				that.selectedDestinationId = id
			},
			deleteDestination(){
                let that = this;
				that.loadingAction = true
				var id = that.selectedDestinationId
                let data = {id: id}
                that.idestination = data
				
				let url    = '/webhook/destination/remove'
				let method = 'POST'

				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					that.loadingAction 	 = false;
                    that.getDestinations();
                    that.notifyMe(response.data.message, 'success');
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.loadingAction 	 = false
				})
				that.$bvModal.hide('modal-delete-destination');
            },
			saveActive(row) {
                let that = this;
                let url    = 'webhook/destination/active';
                let method = 'POST';
                let data   = {id:row.id,active:!row.active};
                that.$store.dispatch('apiRequest',{url,method,data}).then((response) => {
                    if(response.data.success) {
                        that.notifyMe(response.data.message,'success')
                    }
                });
                that.$bvModal.hide('modal-delete-smtp');
            },
		},
		watch: {
			itemIdsArray: function (val) {
				let that = this, items = []
				that.allSelected = false;
				
				that.myDestinations.forEach( function(item) {
					if( val.includes(item.id) ) {
						items.push(item.id)
					}
				} )
				
				that.$emit('actionClick', items, 'itemIdsArray' );
			},
			
		},
		computed: {
			HeightLayer() {
				let columns = this.ColumnBlur > 5 ? 5 : this.ColumnBlur
				return {height: 'calc( 58px * ' + columns + ' )'}
			},
			filteredDestinations: function () {
				var sortKey = this.sortKey
				var filterKey = this.searchData && this.searchData.toLowerCase()
				var order = this.sortOrders[sortKey] || 1
				var myDestinations = this.myDestinations
				if (filterKey) {
					myDestinations = myDestinations.filter(function (row) {
						return Object.keys(row).some(function (key) {
							return String(row[key]).toLowerCase().indexOf(filterKey) > -1
						})
					})
				}
				if (sortKey) {
					myDestinations = myDestinations.slice().sort(function (a, b) {
						a = a[sortKey]
						b = b[sortKey]
						return (a === b ? 0 : a > b ? 1 : -1) * order
					})
				}
				return myDestinations
			}
		},
		
		filters: {
			capitalize: function (str) {
				return str.charAt(0).toUpperCase() + str.slice(1)
			},
			maxLength: function (str) {
				if( str != null && str != '' ) {
					return str.trim().length > 18 ? str.trim().substring(0, 18) + "...." : str.trim()
				} else {
					return ''
				}
			}
		},
		
	}
	
</script>

<style lang="scss">

	.color-797979{
		color:#797979;
	}
	
	.width-90{
		width:90px;
	}
	
	.width-100{
		width:100px;
	}
	
	.width-120{
		width:120px;
	}
	
	.width-150{
		width:150px;
	}
	
	.width-200{
		width:200px !important;
		min-width:200px !important;
	}
	
	.width-250{
		width:250px !important;
		min-width:250px !important;
	}
	
	.width-150{
		width:150px !important;
		min-width:150px !important;
	}
	
	.column-blur {
		filter: blur(8px);
	}
	
	.overlay-blur {
		position: absolute;
		top: 46px;
		width: 100%;
		z-index: 9;
		padding: 16px;
		backdrop-filter: blur(6px);
		text-align: center;
		
		align-items: center;
		display: flex;
		justify-content: center;
		>.col-block {
			width: 25em;
			.upgrade-img {
				width: 155px;
			}
		}
		>div {
			.p-upgrade {
				font-weight: 500;
				font-size: 16px;
				margin: 8px 0px;
			}
			.btn-upgrade {
				width: 15em;
				padding: 10px;
			}
		}
		>.inline {
			display: flex;
			width: 44em;
			.upgrade-img {
				height: 50px;
				margin: auto;
			}
			>div {
				margin: auto;
			}
		}
	}
	
	[md-tooltip] {
		position:relative;
	}
	[md-tooltip]:before {
		position: absolute;
		right: 0;
		top: -38px;
		background-color: #171717;
		color: #ffffff;
		height: 30px;
		line-height: 30px;
		border-radius: 5px;
		padding: 0 15px;
		content: attr(content);
		white-space: nowrap;
		display: none;
		transition:.25s ease-in-out;
		font-weight: 600;
		min-width: 85%;
		text-align: center;
	}
	[md-tooltip]:after {
		position: absolute;
		right: 45%;
		top: -8px;
		border-top: 7px solid #171717;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		content: "";
		display: none;
		transition:.25s ease-in-out;
	}
	[md-tooltip]:hover:after, [md-tooltip]:hover:before {
		display: block;
		transition:.25s ease-in-out;
	}
	
	.table-grid {
		
		.dropdown-bulk-actions-md {
			.dropdown-toggle {
				padding: 0px 15px;
			}
			.dropdown-item:hover {
				color: #fff;
			}
		}
		
		.searchData-md {
			padding: 12px 0px;
			float: right;
		}
		
		.table-md-hover {
			tbody {
				tr {
					border-bottom: 1px solid #eee;
					height: 55px;
					&:hover {
						
						td:first-child:before {
							
							height	  : 58px;
							width	  : 100%;
							position  : absolute;
							right	  : 0px;
							content	  : '';
							
							box-shadow: 0px 0px 30px -14px #404040;
							-webkit-box-shadow: 0px 0px 30px -14px #404040;
							
							-webkit-transition: all 200ms ease;
							transition: all 200ms ease;
							
							margin: -17px 0px;
							
							z-index: 0;
						}
					}
				}
			}
		}
		.table-md {
			
			tbody > tr:nth-child(2n+1) > td, tbody > tr:nth-child(2n+1) > th {
				background-color: #f8fdff;
			}
			width: 100%;
			word-break: break-all;
			
			tbody {
				
				tr {
					
					td {
						vertical-align: middle;
						max-width: 126px;
						.status-badge-md {
							color: #fff;
							margin-right: 7px;
							border-radius: 4px;
							padding: 3px 8px;
							font-style: normal;
						}
						
						.actions-btn-md {
							background-color: #e5e5e5;
							border-radius: 4px;
							padding: 4px 12px;
							font-size: 12px;
							cursor: pointer;
							color: #7b7b7b;
							text-align: center;
							max-width: 6em;
							position: relative;
						}
						.actions-btn-md:hover {
							color: #FFF;
							background-color: #377dff;
						}
						
						.details-action-md {
							.email, .phone {
								z-index: 1;
								display: block;
							}
							.email-action {
								min-width: 13.5em;
							}
							.phone-action {
								min-width: 10em;
							}
							.action {
								display: none;
								
								.send-mail, .copy, .call-event {
									background-color: #eee;
									cursor: pointer;
									display: inline-block;
									padding: 8px 6px 8px 6px;
									height: 32px;
									font-weight: 600;
									font-size: 11px;
								}
								.send-mail, .call-event {
									border-radius: 30px 0px 0px 30px;
									border-right: 1px solid #dcdcdc;
									
									a {
										color: #000;
									}
									
									.fa {
										padding-right: 4px;
										/* color: #92cf5c; */
									}
								}
								
								.copy {
									border-radius: 0px 30px 30px 0px;
								}
								
							}
							&:hover {
								.email, .phone, small {
									display: none;
								}
								.action {
									display: block;
								}
							}
						}
						
					}
					
				}
			}
			
			thead th {
				border-bottom: 1px solid #e7eaf3;
			}
			
			th {
				/* background-color: #42b983; */
				/* color: rgba(255,255,255,0.66); */
				color: #000;
				cursor: pointer;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			
			th, td {
				padding: 10px 10px;
				font-size: 13px;
			}
			
			/* th.active {
				color: #fff;
			} */
			
			th.active .arrow {
				opacity: 1;
			}
			
			.arrow {
				display: inline-block;
				vertical-align: middle;
				width: 0;
				height: 0;
				margin-left: 5px;
				opacity: 0.66;
				
				float: right;
				margin-top: 8px;
				
			}
			
			.arrow.asc {
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-bottom: 4px solid #000;
			}
			
			.arrow.dsc {
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-top: 4px solid #000;
			}
			
		}
	}
	
	.popover {
		.popover-header {
			font-size: 13px;
		}
		.popover-body {
			font-size: 12px;
		}
		.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
			border-bottom-color: #f7f7f7;
		}
	}
	
	.column_being_verified {
		.verification-progress {
			position: absolute;
			background: #bfbfbfd6;
			width: 100%;
			left: 0px;
			top: 0px;
			height: 100%;
			padding: 10px;
			text-align: center;
			font-size: 10px;
			z-index: 9;
		}
	}
	.column_being_verified {
		position: relative;
	}
	.column_not_valid {
		background-color: #ffefef !important;
	}
	.column_valid {
		background-color: #e2ffe9 !important;
	}
	
	.statusUserAction {
		option {
			background-color: #fff;
		}
	}
	
	
	
	.oml-destination-datatable{
		tr{
			&:hover{
				position:relative;
				
				td:first-child:before{
					top:0 !important;
					margin:0 !important;
					height:100% !important;
				}
			}
		}
		td{
			font-size:13px;
			vertical-align:middle;
			padding:5px 10px !important;

			.btn-group{
				button{
					padding:0;
					line-height:16px;

					i{
						padding:0;
					}
				}
			}

			.margin-auto{
				margin:0 auto;
			}

			.fw-700{
				font-weight:700;
			}

			.oml-destStatus{
				border-radius:4px;
				color:#fff;
				padding:1px 2px;
				font-size:13px;
				margin-bottom: 2px;
				font-weight:400;
				display:inline-block;

				&.qualified{
					background-color:#4caf50;
				}

				&.call-back{
					background-color:#800080;
				}
			}
		}
	}
.oml-destinationsListing-empty {
    
    position: relative!important;
    
}
</style>
