<template>
	<div class="table-grid">
		<table class="table-md"><!-- table-md -->
			<thead>
				<tr>
					<th v-for="(item, key) in columns" @click="sortBy(item.column)" :class="{ active: sortKey == item.column }" v-bind:key="key">
						<!-- {{ key | capitalize }} -->
						{{ item.title }}
						<div class="arrow" :class="sortOrders[item.column] > 0 ? 'asc' : 'dsc'"></div>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(entry, id) in filteredClients" v-bind:key="id">
					<td v-for="(item, key) in columns" v-bind:key="key">
						<!-- {{entry[key]}} -->
						<!-- {{ entry[item.column] }} -->
						<div v-if="item.column == 'actions'" class="pl-2 pr-2">
						
							<b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" right no-caret>
								<template v-slot:button-content>
									<i class="fa fa-ellipsis-v dropdown-icon"></i>
								</template>
								<b-dropdown-item @click="actionElement('edit', entry)"><i class="fa fa-cog pr-1"></i> {{ $t('settings')}}</b-dropdown-item>
								<b-dropdown-item v-if="id != 0 && $auth.user().id == entry.owner_id" @click="actionElement('delete', entry)">
									<i class="fa fa-trash pr-1"></i> {{$t('DeleteWorkspace')}}
								</b-dropdown-item>
								<b-dropdown-item v-else-if="$auth.user().id != entry.owner_id" @click="actionElement('leave', entry)"><i class="fa fa-trash pr-1"></i> {{$t('Leaveworkspace')}}</b-dropdown-item>
							</b-dropdown>
							
							<!--span>  v-if="$auth.user().id == entry.owner_id"
								<i class="fa fa-pencil pr-3" title="Edit" style="color: #007bff;cursor: pointer;"></i>
								<i v-if="id != 0" class="fa fa-trash" title="Remove" style="color: #ff2828;cursor: pointer;"></i>
							</span-->
						</div>
						<div v-else v-html="entry[item.column]"></div>
					</td>
				</tr>
				
				<tr v-if="filteredClients.length == 0">
					<td :colspan="columns.length"><center>{{ $t('Nodatatodisplay')}}</center></td>
				</tr>
				
			</tbody>
		</table>
	</div>
</template>

<script>
	// register the grid component
	
	export default {
		props: {
			dataItems: Array,
			columns	 : Array,
			searchQuery	: String
		},
		data() {
			var sortOrders = {}
			this.columns.forEach(function (key) {
				sortOrders[key.column] = 1
			})
			return {
				sortKey: '',
				sortOrders: sortOrders,
				/* searchQuery: '' */
			}
		},
		
		methods: {
			actionElement: function (action, entry) {
				this.$emit('modelAction', action, entry );
			},
			
			sortBy: function (key) {
				this.sortKey = key
				this.sortOrders[key] = this.sortOrders[key] * -1
			},
			
		},
		
		computed: {
			filteredClients: function () {
				var sortKey = this.sortKey
				var filterKey = this.searchQuery && this.searchQuery.toLowerCase()
				var order = this.sortOrders[sortKey] || 1
				var dataItems = this.dataItems
				if (filterKey) {
					dataItems = dataItems.filter(function (row) {
						return Object.keys(row).some(function (key) {
							return String(row[key]).toLowerCase().indexOf(filterKey) > -1
						})
					})
				}
				if (sortKey) {
					dataItems = dataItems.slice().sort(function (a, b) {
						a = a[sortKey]
						b = b[sortKey]
						return (a === b ? 0 : a > b ? 1 : -1) * order
					})
				}
				return dataItems
			}
		},
		filters: {
			maxLength: function (str) {
				return str.trim().length > 18 ? str.trim().substring(0, 18) + "...." : str.trim()
			}
		}
	}
	
</script>

<style lang="scss">
	
</style>
