<template>
	<div class="select-list-wrapper">
		<header class="select-list-actions is-clearfix">
			<input type="text" class="form-control-plaintext p-0" v-model="filterText" :placeholder="$t('Search')" />
			<i class="fa fa-search"></i>
		</header>
		<div class="select-list" v-if="filteredOptions.length > 0">
			<div v-if="multiple && selectAllCheck" class="button toggle-button" @click="selectAllEvent()">
				<i class="fa fa-check-square-o" v-if="selectAll"></i>
				<i class="fa fa-square-o" v-else></i>
				<span><b>{{ $t('SelectAll')}}</b></span>
			</div>
			<div v-if="titleOption != '' && titleOption != null" class="option-drop">{{ titleOption }}</div>
			<div v-for="(option, i) in filteredOptions" v-bind:key="i">
				<div role="checkbox" :class="[ isSelected[option.id] ? 'is-selected' : '', 'button', 'toggle-button']" @click="onToggle(option)">
					<!--  class="button toggle-button"  :class="[ isSelected[option.id] ? 'is-selected' : '', 'button', 'toggle-button']" -->
					<template v-if="multiple">
						<i class="fa fa-check-square-o" v-if="isSelected[option.id]"></i>
						<i class="fa fa-square-o" v-else></i>
					</template>
					<template v-else>
						<i class="fa fa-check fa-select-toggle"></i>
					</template>
					
					<!--span v-html="TextValue(option)" :id="option.id"></span-->
					<span v-html="option.value" :id="option.id"></span>
					<span v-html="option.tag" v-if="option.tag != null" class="badge-tag badge badge-success"></span>
				</div>
			</div>
		</div>
		<div class="p-2 text-center" v-else style="font-size: 14px;">{{ $t('NoItems')}}</div>
	</div>
</template>
<script>
	export default {
		components: {
			
		},
		data() {
			return { 
				filterText		: '',
				selectedOptions	: this.selected,
				isSelected		: [],
				selectAll 		: false,
			};
		},
		props: {
			'options'		: Array,
			'selected'		: Array,
			'defaultSelected': String,
			'multiple'		: Boolean,
			'selectAllCheck': Boolean,
			'titleOption'	: String
		},
		methods: {
			
			selectAllEvent() {
				let that = this
				
				that.selectAll = !that.selectAll
				
				that.options.forEach( function(item) {
					that.isSelected[item.id] = false
					that.selectedOptions.splice(that.selectedOptions.indexOf(item.id), 1);
				} )
				
				if( that.selectAll ) {
					that.options.forEach( function(item) {
						that.isSelected[item.id] = true
						that.selectedOptions.push(item.id);
						
						// $event.preventDefault();
						that.$emit('change', { changed: item, selected: that.selectedOptions });
						
					} )
				} else {
					that.options.forEach( function(item) {
						that.isSelected[item.id] = false
						that.selectedOptions.splice(that.selectedOptions.indexOf(item.id), 1);
						
						// $event.preventDefault();
						that.$emit('change', { changed: item, selected: that.selectedOptions });
						
					} )
				}
				
			},
			
			onToggle(option) {
				let $event = event
				let that = this, checkedAll = true
				
				if( that.multiple ) {
					
					that.isSelected[option.id] = ( that.isSelected[option.id] == true ) ? false : true
					that.options.forEach( function(item) {
						if( !that.isSelected[item.id] ) {
							checkedAll = false
						}
					} )
					if ( checkedAll && that.isSelected[option.id] ) {
						that.selectAll = true
					} else {
						that.selectAll = false
					}
					if ( that.isSelected[option.id] ) {
						that.selectedOptions.push(option.id);
					} else {
						that.selectedOptions.splice(that.selectedOptions.indexOf(option.id), 1);
					}
					$event.preventDefault();
					// that.$emit( 'change' );
					that.$emit('change', option, that.selectedOptions );
					
				} else {
					
					that.options.forEach( function(item) {
						if( that.isSelected[item.id] && option.id != item.id ) {
							that.isSelected[item.id] = false
							that.selectedOptions.splice(that.selectedOptions.indexOf(option.id), 1);
						}
					} )
					
					// console.log(that.isSelected[item.id], option.id, item.id)
					// that.isSelected[option.id] = ( that.isSelected[option.id] == true ) ? false : true
					
					if ( that.isSelected[option.id] ) {
						that.selectedOptions.push(option.id);
						that.isSelected[option.id] = false
					} else {
						that.selectedOptions.splice(that.selectedOptions.indexOf(option.id), 1);
						that.isSelected[option.id] = true
					}
					$event.preventDefault();
					// that.$emit('change', 'option' );
					that.$emit('change', option, that.selectedOptions );
					
				}
				
			},
			
			TextValue(option) {
				let String = option.value
				if( String != null && String != '' ) {
					return String.length > 15 ? String.substring(0, 15) + "..." : String
				} else {
					return ''
				}
				
			}
			
		},
		watch: {
			
			options: {
				handler: function (val) { // , oldVal
					let that = this
					that.selectAll = (that.defaultSelected == 'All') ? true : false
					
					val.forEach( function(item) {
						that.isSelected[item.id] = false
					} )
					/* that.selectAll = (that.defaultSelected == 'All') ? true : false
					val.forEach( function(item) {
						// that.isSelected[item.id] = (that.defaultSelected == 'All') ? true : false
						if( that.selectAll ) {
							// that.selectedOptions.push(item.id);
							// that.$emit('change', { changed: item, selected: that.selectedOptions });
							that.isSelected[item.id] = true
						} else {
							that.isSelected[item.id] = false
						}
					} ) */
				},
				deep: true
			},
			
			/* options: {
				handler: function (val) { // , oldVal
					let that = this
					that.selectAll = false
					val.forEach( function(item) {
						that.isSelected[item.id] = false
					} )
				},
				deep: true
			}, */
			
			selected: function (val) {
				let that = this
				val.forEach( function(item) {
					that.isSelected[item] = true
				} )
			},
		},
		computed: {
			filteredOptions() {
				var visibleOptions = [],
				filterText = this.filterText.trim().length > 0 ? this.filterText.toLowerCase() : null;
				
				if (filterText) {
					/* visibleOptions = this.options.filter(item => {
						let option = item.title, optWords = option.split(' ');
						return optWords.some(word => {
							return word.toLowerCase().indexOf(filterText) === 0;
						});
					}); */
					
					visibleOptions = this.options.filter((item) => {
						return filterText.toLowerCase().split(' ').every(v => item.title.toLowerCase().includes(v));
					});
				} else {
					visibleOptions = this.options;
				}
				
				return visibleOptions.map(item => {
					let label = '', option = item.title, optionId = item.id;
					if (filterText) {
						
						if( filterText.indexOf(' ') >= 0 ) {
							let arChar = filterText.split(' ')
							label = option;
							arChar.forEach( function( str ) {
								label = label.replace(str, '<b>'+ str +'</b>');
							} )
						} else {
							let searchStartIndex = option.toLowerCase().indexOf(filterText), 
							filterPart = option.substring(searchStartIndex, searchStartIndex + filterText.length);
							label = option.replace(filterPart, '<b>'+ filterPart +'</b>');
						}
						
					} else {
						label = option;
					}
					return {
						value	: label,
						id		: optionId,
						label	: label,
						tag		: item.tag,
						enable	: item.enable,
						selected: this.selectedOptions.includes(label)
					};
				});
			} 
		},
		
		filters: {},
		
	}
</script>
<style lang="scss">
	.badge-tag {
		position: absolute;
		right: 5px;
		top: 8px;
	}
</style>
